import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, Avatar } from '@mui/material';
import { Email } from '@mui/icons-material';
import Logo from '../../logo-r.png';
import LoginBG from '../../assets/bg/login-bg.jpg';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Validate email format
    setEmailValid(emailRegex.test(value) || !formSubmitted);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (email.length > 0 && emailRegex.test(email)) {
      // Simulate email submission or API request for password reset
      console.log('Password reset link sent to:', email);
      // Pass email as state to the OTP page
      navigate('/user/otp', { state: { email } }); 
    } else {
      setErrorMessage('Please enter a valid email address');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        backgroundImage: `url(${LoginBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '800px',
          height: '600px',
          display: 'flex',
          flexDirection: 'row',
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: '8px',
          boxShadow: 2,
        }}
      >
        {/* Left Area */}
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#9acb4eb0' }}>
          <Avatar src={Logo} sx={{ width: '100%', height: 'auto', maxWidth: '500px', padding: '20px' }} />
        </Box>

        {/* Right Area */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <Typography variant="h5" gutterBottom>ZTC Console</Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>Zventure Corporation</Typography>

          {/* Error Messages */}
          {(!emailValid && formSubmitted) || errorMessage ? (
            <Box sx={{ mb: 2, color: 'red' }}>
              <Typography variant="body2">
                {errorMessage || (!emailValid && formSubmitted ? 'Email is required' : '')}
              </Typography>
            </Box>
          ) : null}

          <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
            {/* Email Input */}
            <TextField
              fullWidth
              variant="outlined"
              label="Email Address"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              error={!emailValid && formSubmitted}
              helperText={!emailValid && formSubmitted ? 'Email is required' : ''}
              InputProps={{
                startAdornment: <Email sx={{ mr: 1 }} />,
              }}
              sx={{
                mb: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                },
                '& .MuiInputBase-input': {
                  backgroundColor: 'transparent',
                }
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
            >
              Send Reset Link
            </Button>

            <Typography variant="body2" color="textSecondary" align="center">
              &copy; {new Date().getFullYear()} Zventure Corporation. All rights reserved.
            </Typography>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
