import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
} from '@mui/material';
import { AccountCircle, Phone, LocationOn, Email } from '@mui/icons-material';
import Notification from '../../components/common/Notification/Notification';

function AddChemist() {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    fullName: '',
    address: '',
    city: '',
    thana: '',
    district: '',
    mobile1: '',
    mobile2: '',
    phone: '',
    gender: '',
    chemistType: 'chemist', // Hidden but used in API
    description: '',
    shopName: '',
    shopCity: '',
    contactPersonName: '',
    contactCell: '',
    contactEmail: ''
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const validateForm = () => {
    let errors = {};

    if (!formValues.fullName.trim()) {
      errors.fullName = 'Full name is required';
    }
    if (!formValues.mobile1.trim()) {
      errors.mobile1 = 'Mobile number is required';
    } else if (!/^\+880\d{10}$/.test(formValues.mobile1)) {
      errors.mobile1 = 'Invalid mobile number format. Example: +8801700000121';
    }
    if (!formValues.shopName.trim()) {
      errors.shopName = 'Shop name is required';
    }
    if (!formValues.contactPersonName.trim()) {
      errors.contactPersonName = 'Contact person name is required';
    }
    if (!formValues.contactCell.trim()) {
      errors.contactCell = 'Contact cell is required';
    } else if (!/^\+880\d{10}$/.test(formValues.contactCell)) {
      errors.contactCell = 'Invalid contact cell format. Example: +8801700000121';
    }
    if (!formValues.contactEmail.trim()) {
      errors.contactEmail = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.contactEmail)) {
      errors.contactEmail = 'Invalid email format';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted', { ...formValues });

      // Clear the form
      handleReset();

      // Show success notification
      setOpenSnackbar(true);
    }
  };

  const handleReset = () => {
    setFormValues({
      fullName: '',
      address: '',
      city: '',
      thana: '',
      district: '',
      mobile1: '',
      mobile2: '',
      phone: '',
      gender: '',
      chemistType: 'chemist',
      description: '',
      shopName: '',
      shopCity: '',
      contactPersonName: '',
      contactCell: '',
      contactEmail: ''
    });
    setFormErrors({});
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Add New Chemist</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form onSubmit={handleSubmit} onReset={handleReset}>
            {/* Full Name */}
            <div className="mb-6 pb-4">
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                name="fullName"
                value={formValues.fullName}
                onChange={handleInputChange}
                error={!!formErrors.fullName}
                helperText={formErrors.fullName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </div>

            {/* Contact Address */}
            <div className="mb-6">
              <TextField
                label="Contact Address"
                variant="outlined"
                multiline
                rows={2}
                fullWidth
                name="address"
                value={formValues.address}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </div>

            {/* City, Thana, District, Gender */}
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mb-6">
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                name="city"
                value={formValues.city}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
              <TextField
                label="Thana"
                variant="outlined"
                fullWidth
                name="thana"
                value={formValues.thana}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
              <TextField
                label="District"
                variant="outlined"
                fullWidth
                name="district"
                value={formValues.district}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
              <FormControl fullWidth variant="outlined" className="mt-1">
                <InputLabel>Gender</InputLabel>
                <Select
                  label="Gender"
                  name="gender"
                  value={formValues.gender}
                  onChange={handleInputChange}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </div>

            {/* Phone, Mobile1, Mobile2 */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                name="phone"
                value={formValues.phone}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
              <TextField
                label="Mobile 1"
                variant="outlined"
                fullWidth
                name="mobile1"
                value={formValues.mobile1}
                onChange={handleInputChange}
                error={!!formErrors.mobile1}
                helperText={formErrors.mobile1}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
              <TextField
                label="Mobile 2"
                variant="outlined"
                fullWidth
                name="mobile2"
                value={formValues.mobile2}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </div>

            {/* Short Description */}
            <div className="mb-6">
              <TextField
                label="Short Description"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                name="description"
                value={formValues.description}
                onChange={handleInputChange}
                inputProps={{ maxLength: 3000 }} // Limit the short description to 3000 characters
                className="mt-1"
              />
            </div>

            {/* Shop Details */}
            <div className="border rounded-md p-4 mb-6 bg-gray-50">
              <h3 className="text-lg font-semibold mb-2">Shop Details</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <TextField
                  label="Shop Name"
                  variant="outlined"
                  fullWidth
                  name="shopName"
                  value={formValues.shopName}
                  onChange={handleInputChange}
                  error={!!formErrors.shopName}
                  helperText={formErrors.shopName}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Shop City"
                  variant="outlined"
                  fullWidth
                  name="shopCity"
                  value={formValues.shopCity}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <TextField
                label="Shop Address"
                variant="outlined"
                multiline
                rows={2}
                fullWidth
                name="shopAddress"
                value={formValues.shopAddress}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            {/* Contact Person */}
            <div className="border rounded-md p-4 bg-gray-50 mb-6">
              <h3 className="text-lg font-semibold mb-2">Contact Person</h3>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                <TextField
                  label="Contact Person Name"
                  variant="outlined"
                  fullWidth
                  name="contactPersonName"
                  value={formValues.contactPersonName}
                  onChange={handleInputChange}
                  error={!!formErrors.contactPersonName}
                  helperText={formErrors.contactPersonName}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Contact Cell"
                  variant="outlined"
                  fullWidth
                  name="contactCell"
                  value={formValues.contactCell}
                  onChange={handleInputChange}
                  error={!!formErrors.contactCell}
                  helperText={formErrors.contactCell}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Contact Email"
                  variant="outlined"
                  fullWidth
                  name="contactEmail"
                  value={formValues.contactEmail}
                  onChange={handleInputChange}
                  error={!!formErrors.contactEmail}
                  helperText={formErrors.contactEmail}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="reset"
                className="bg-gray-200 text-gray-700 py-2 px-4 rounded-md mr-2"
              >
                Reset
              </button>
              <button
                type="submit"
                className="bg-customGreen text-white py-2 px-4 rounded-md"
              >
                Add Chemist
              </button>
            </div>
          </form>
        </div>
      </div>

      <Notification
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        message="Chemist added successfully!"
        severity="success"
      />
    </div>
  );
}

export default AddChemist;
