import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { 
  Dashboard as DashboardIcon, 
  ExpandMore as ExpandMoreIcon, 
  ExpandLess as ExpandLessIcon, 
  Inventory as InventoryIcon, 
  ShoppingCart as ShoppingCartIcon, 
  Category as CategoryIcon, 
  Receipt as ReceiptIcon, 
  Settings as SettingsIcon, 
  Store as StoreIcon, 
  Badge as BadgeIcon,
  Schedule as ScheduleIcon,
  GroupAdd as GroupAddIcon,
  DocumentScanner as DocumentScannerIcon,
  Diversity3 as Diversity3Icon,
  Tour as TourIcon
} from '@mui/icons-material'; // Add more icons as needed

const menuItems = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardIcon />,
  },
  {
    label: 'Doctor',
    icon: <GroupAddIcon />,
    subItems: [
      { label: 'Manage Doctor', path: '/list-doctor' },
      { label: 'Create Doctor', path: '/add-doctor' },
    ],
  },
  {
    label: 'Chemist',
    icon: <GroupAddIcon />,
    subItems: [
      { label: 'Manage Chemist', path: '/list-chemist' },
      { label: 'Create Chemist', path: '/add-chemist' },
    ],
  },
  {
    label: 'Gift Plan',
    icon: <GroupAddIcon />,
    subItems: [
      { label: 'Manage Plan', path: '/gift-planning' },
      { label: 'Create Plan', path: '/new-planning' }
    ],
  },
  {
    label: 'Tour Plan',
    icon: <TourIcon />,
    subItems: [
      { label: 'View All', path: '/plan-list' },
    ],
  },
  {
    label: 'Leave / Meeting',
    icon: <ScheduleIcon />,
    subItems: [
      { label: 'Meeting', path: '/meeting' },
      { label: 'Leave', path: '/leave' },
    ],
  },
  {
    label: 'Prescription',
    icon: <DocumentScannerIcon />,
    subItems: [
      { label: 'Prescription list', path: '/list-prescription' },
    ],
  },
  {
    label: 'Employee',
    icon: <BadgeIcon />,
    subItems: [
      { label: 'Employee List', path: '/list-employee' },
      { label: 'Employee Log', path: '/employee-log' },
    ],
  },
  {
    label: 'Suppliers',
    icon: <StoreIcon />,
    subItems: [
      { label: 'Supplier List', path: '/list-supplier' },
    ],
  },
  {
    label: 'Stock Management',
    icon: <InventoryIcon />,
    subItems: [
      { label: 'Purchase List', path: '/purchase-history' },
      { label: 'Stock List', path: '/stock-list' },
    ],
  },
  {
    label: 'Product',
    icon: <CategoryIcon />,
    subItems: [
      { label: 'Product List', path: '/list-product' },
    ],
  },
  {
    label: 'Customer',
    icon: <Diversity3Icon />,
    subItems: [
      { label: 'Customer List', path: '/list-customer' },
    ],
  },
  {
    label: 'Order',
    icon: <ShoppingCartIcon />,
    subItems: [
      { label: 'Manage Order', path: '/manage-order' },
    ],
  },
  {
    label: 'Sales',
    icon: <ReceiptIcon />,
    subItems: [
      { label: 'Sales List', path: '/list-sales' },
      { label: 'Damage List', path: '/list-damage' },
      { label: 'Transaction', path: '/transaction-history' },
    ],
  },
  {
    label: 'Settings',
    icon: <SettingsIcon />,
    subItems: [
      { label: 'Product Categories', path: '/product-categories' },
      { label: 'User Permission', path: '/user-permission' },
    ],
  },
];

const Sidebar = () => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState('');

  useEffect(() => {
    const activeMenu = menuItems.find(item => 
      item.subItems?.some(subItem => location.pathname.startsWith(subItem.path))
    );
    setOpenMenu(activeMenu?.label || '');
  }, [location.pathname]);

  // Function to determine if a link is active
  const isActive = (path) => location.pathname === path;

  const toggleMenu = (label) => {
    setOpenMenu(prev => prev === label ? '' : label);
  };

  return (
    <aside className="w-64 bg-white text-gray-600 pt-5 fixed top-0 left-0 h-full mt-15 overflow-x-auto" style={{ width: '16rem' }}>
      {/* Sidebar content */}
      <nav>
        <ul>
          {menuItems.map((item) => (
            <li key={item.label}>
              {item.subItems ? (
                <>
                  <div 
                    className="flex items-center py-2 px-4 cursor-pointer hover:bg-gray-200 hover:text-green-500 transition-colors duration-300"
                    onClick={() => toggleMenu(item.label)}
                    aria-expanded={openMenu === item.label}
                  >
                    {item.icon} {/* Icon */}
                    <span className="ml-3 font-medium">{item.label}</span> {/* More space between icon and text */}
                    {openMenu === item.label ? <ExpandLessIcon className="ml-auto" /> : <ExpandMoreIcon className="ml-auto" />}
                  </div>
                  {openMenu === item.label && (
                    <ul className="pl-8 mt-2">
                      {item.subItems.map((subItem) => (
                        <li key={subItem.path}>
                          <Link 
                            to={subItem.path} 
                            className={`flex items-center py-2 px-4 transition-colors duration-300 ${isActive(subItem.path) ? 'bg-gray-100 text-green-500' : 'hover:bg-gray-100 hover:text-green-500'}`}
                          >
                            <span className="ml-3">{subItem.label}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              ) : (
                <Link 
                  to={item.path} 
                  className={`flex items-center py-2 px-4 transition-colors duration-300 ${isActive(item.path) ? 'bg-gray-100 text-green-500' : 'hover:bg-gray-100 hover:text-green-500'}`}
                >
                  {item.icon} {/* Icon */}
                  <span className="ml-3 font-medium">{item.label}</span> {/* More space between icon and text */}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
