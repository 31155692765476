import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, TextField, Button, Avatar } from '@mui/material';
import CryptoJS from 'crypto-js'; // Make sure to install this package
import Logo from '../../logo-r.png';
import OTPBG from '../../assets/bg/login-bg.jpg';

const OTPVerificationPage = () => {
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [otpValid, setOtpValid] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email; // Retrieve the email passed from ForgotPassword

  const inputRefs = useRef([]);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setOtpValid(newOtp.join('').length === 6 || !formSubmitted);

      // Focus next input field if the user has typed a digit
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const otpString = otp.join('');

    if (otpString.length === 6) {
      // Encrypt the OTP using a secret key (this should be stored securely in a real app)
      const secretKey = 'your-secret-key'; // Replace with your own secret key
      const encryptedOtp = CryptoJS.AES.encrypt(otpString, secretKey).toString();

      // Log the encrypted OTP to the console (simulating sending it to a server)
      console.log('Encrypted OTP:', encryptedOtp);

      // Mock validation for demo purposes
      if (otpString === '123456') {
        alert('OTP Verified Successfully! Redirecting to set new password...');
        navigate('/user/set-new-password'); // Redirect to set new password after successful OTP verification
      } else {
        setErrorMessage('Invalid OTP, please try again.');
      }
    } else {
      setErrorMessage('OTP is required and must be 6 digits.');
    }
  };

  const maskedEmail = email ? email.replace(/(.{2})(.*)(@.*)/, '$1*****$3') : 'not provided';

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        backgroundImage: `url(${OTPBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '800px',
          height: '600px',
          display: 'flex',
          flexDirection: 'row',
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: '8px',
          boxShadow: 2,
        }}
      >
        {/* Left Area */}
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#9acb4eb0' }}>
          <Avatar src={Logo} sx={{ width: '100%', height: 'auto', maxWidth: '500px', padding: '20px' }} />
        </Box>

        {/* Right Area */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <Typography variant="h5" gutterBottom>OTP Verification</Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>Zventure Corporation</Typography>

          {/* Status Message */}
          <Box sx={{ mb: 2, textAlign: 'center' }}>
            <Typography variant="body1">Enter the OTP you received on:</Typography>
            <Typography variant="body1">{maskedEmail}</Typography>
          </Box>

          {/* Error Messages */}
          {(!otpValid && formSubmitted) || errorMessage ? (
            <Box sx={{ mb: 2, color: 'red' }}>
              <Typography variant="body2">
                {errorMessage || (!otpValid && formSubmitted ? 'OTP must be 6 digits.' : '')}
              </Typography>
            </Box>
          ) : null}

          <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
            {/* OTP Inputs */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  id={`otp-input-${index}`}
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  variant="outlined"
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  sx={{ width: '40px', mx: 1 }}
                  inputProps={{ maxLength: 1 }}
                />
              ))}
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
            >
              Verify OTP
            </Button>

            <Typography variant="body2" color="textSecondary" align="center">
              &copy; {new Date().getFullYear()} Zventure Corporation. All rights reserved.
            </Typography>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default OTPVerificationPage;
