import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
} from '@mui/material';
import { AccountCircle, Phone, LocationOn, PhotoCamera } from '@mui/icons-material';
import Notification from '../../components/common/Notification/Notification';

function AddDoctor() {
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    fullName: '',
    address: '',
    city: '',
    thana: '',
    district: '',
    mobile1: '',
    mobile2: '',
    phone: '',
    gender: '',
    doctorType: 'doctor', // Hidden but used in API
    description: ''
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file && ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
      setAvatar(file.name);
      setAvatarPreview(URL.createObjectURL(file));
      setFormErrors((prev) => ({ ...prev, avatar: '' }));
    } else {
      setAvatar(null);
      setAvatarPreview(null);
      setFormErrors((prev) => ({ ...prev, avatar: 'Invalid file type. Please upload JPEG, JPG, or PNG.' }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const validateForm = () => {
    let errors = {};

    if (!formValues.fullName.trim()) {
      errors.fullName = 'Full name is required';
    }
    if (!formValues.mobile1.trim()) {
      errors.mobile1 = 'Mobile number is required';
    } else if (!/^\+880\d{10}$/.test(formValues.mobile1)) {
      errors.mobile1 = 'Invalid mobile number format. Example: +8801700000121';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted', { ...formValues, avatar });

      // Clear the form
      handleReset();

      // Show success notification
      setOpenSnackbar(true);
    }
  };

  const handleReset = () => {
    setFormValues({
      fullName: '',
      address: '',
      city: '',
      thana: '',
      district: '',
      mobile1: '',
      mobile2: '',
      phone: '',
      gender: '',
      doctorType: 'doctor',
      description: ''
    });
    setAvatar(null);
    setAvatarPreview(null);
    setFormErrors({});
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Add New Doctor</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form onSubmit={handleSubmit} onReset={handleReset}>
            {/* Full Name */}
            <div className="mb-6 pb-4">
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                name="fullName"
                value={formValues.fullName}
                onChange={handleInputChange}
                error={!!formErrors.fullName}
                helperText={formErrors.fullName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </div>

            {/* Address */}
            <div className="mb-6">
              <TextField
                label="Contact Address"
                variant="outlined"
                multiline
                rows={2}
                fullWidth
                name="address"
                value={formValues.address}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </div>

            {/* City, Thana, District, Gender */}
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mb-6">
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                name="city"
                value={formValues.city}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
              <TextField
                label="Thana"
                variant="outlined"
                fullWidth
                name="thana"
                value={formValues.thana}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
              <TextField
                label="District"
                variant="outlined"
                fullWidth
                name="district"
                value={formValues.district}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
              <FormControl fullWidth variant="outlined" className="mt-1">
                <InputLabel>Gender</InputLabel>
                <Select
                  label="Gender"
                  name="gender"
                  value={formValues.gender}
                  onChange={handleInputChange}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </div>

            {/* Phone, Mobile1, Mobile2 */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                name="phone"
                value={formValues.phone}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
              <TextField
                label="Mobile 1"
                variant="outlined"
                fullWidth
                name="mobile1"
                value={formValues.mobile1}
                onChange={handleInputChange}
                error={!!formErrors.mobile1}
                helperText={formErrors.mobile1}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
              <TextField
                label="Mobile 2"
                variant="outlined"
                fullWidth
                name="mobile2"
                value={formValues.mobile2}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </div>

            {/* Remarks and Avatar */}
            <div className="grid grid-cols-3 gap-4 mb-6">
              <TextField
                label="Remarks"
                variant="outlined"
                multiline
                rows={20.5}
                fullWidth
                name="description"
                value={formValues.description}
                onChange={handleInputChange}
                inputProps={{ maxLength: 3000 }} // Limit the Remarks to 3000 characters
                className="col-span-2 mt-1"
                style={{ height: '100px' }} // Set a fixed height to match the avatar box
              />

              {/* Profile Image Upload */}
              <div className="flex flex-col items-center justify-center cursor-pointer">
                <div
                  className="border border-gray-300 p-2 rounded-md mb-2 w-full aspect-square"
                  onClick={() => document.getElementById('avatar-upload').click()}
                >
                  {avatarPreview ? (
                    <img
                      src={avatarPreview}
                      alt="Avatar Preview"
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <div className="h-full w-full bg-gray-200 flex items-center justify-center">
                      <PhotoCamera className="text-gray-500" />
                    </div>
                  )}
                </div>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="avatar-upload"
                  type="file"
                  onChange={handleAvatarChange}
                />
                {formErrors.avatar && <p className="text-red-500">{formErrors.avatar}</p>}
              </div>
            </div>

            {/* Submit and Reset buttons */}
            <div className="flex gap-4">
              <button
                type="submit"
                className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Submit
              </button>
              <button
                type="reset"
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Reset
              </button>
            </div>
          </form>

          {/* Notification Snackbar */}
          <Notification open={openSnackbar} onClose={handleCloseSnackbar} message="Doctor added successfully!" />
        </div>
      </div>
    </div>
  );
}

export default AddDoctor;
