import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Box, Typography, TextField, IconButton, Button, Avatar } from '@mui/material';
import { Visibility, VisibilityOff, Person, Lock } from '@mui/icons-material';
import Logo from '../../logo-r.png';
import LoginBG from '../../assets/bg/login-bg.jpg';

const LoginPage = ({ onLogin }) => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userIdValid, setUserIdValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [loginError, setLoginError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const navigate = useNavigate();

  const handleUserIdChange = (e) => {
    const rawValue = e.target.value;
    const sanitizedValue = rawValue.replace(/[^a-zA-Z0-9]/g, '');
    setUserId(sanitizedValue);
    setUserIdValid(sanitizedValue.length > 0 || !formSubmitted);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordValid(e.target.value.length > 0 || !formSubmitted);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const isUserIdValid = userId.length > 0;
    const isPasswordValid = password.length > 0;

    // Update validity states based on form submission
    setUserIdValid(isUserIdValid || !formSubmitted);
    setPasswordValid(isPasswordValid || !formSubmitted);

    if (isUserIdValid && isPasswordValid) {
      // Check for valid credentials
      if (
        (userId === 'ID01' && password === '123') ||
        (userId === 'demo' && password === 'demo')
      ) {
        const token = 'dummy-auth-token'; // Replace with actual token from your API
        Cookies.set('authToken', token, { expires: 1 });
        onLogin();
        navigate('/dashboard');
      } else {
        setLoginError('Incorrect User Name or Password.');
      }
    } else {
      setLoginError(''); // Clear generic error if specific field errors are present
    }
  };

  // // Navigate to Forgot Password Page
  // const handleForgotPassword = () => {
  //   const encryptedValue = encodeURIComponent(btoa(userId)); // Simulating encryption by using base64
  //   navigate(`/users/password/forgot/?encryptedvalue=${encryptedValue}`);
  // };
  const handleForgotPassword = () => {
    navigate('/user/forgot-password');
  };
  

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        backgroundImage: `url(${LoginBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '800px',
          height: '600px',
          display: 'flex',
          flexDirection: 'row',
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: '8px',
          boxShadow: 2,
        }}
      >
        {/* Left Area */}
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#9acb4eb0' }}>
          <Avatar src={Logo} sx={{ width: '100%', height: 'auto', maxWidth: '500px', padding: '20px' }} />
        </Box>

        {/* Right Area */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <Typography variant="h5" gutterBottom>ZTC Console</Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>Zventure Corporation</Typography>

          {/* Error Messages */}
          {(!userIdValid && formSubmitted) || (!passwordValid && formSubmitted) || loginError ? (
            <Box sx={{ mb: 2, color: 'red' }}>
              <Typography variant="body2">
                {loginError || (!userIdValid && formSubmitted ? 'User Name is required' : '') || (!passwordValid && formSubmitted ? 'Password is required' : '')}
              </Typography>
            </Box>
          ) : null}

          <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
            {/* User Name Input */}
            <TextField
              fullWidth
              variant="outlined"
              label="User Name"
              placeholder='Enter your user name'
              value={userId}
              onChange={handleUserIdChange}
              error={!userIdValid && formSubmitted}
              helperText={!userIdValid && formSubmitted ? 'User Name is required' : ''}
              InputProps={{
                startAdornment: <Person sx={{ mr: 1 }} />,
              }}
              sx={{
                mb: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                },
                '& .MuiInputBase-input': {
                  backgroundColor: 'transparent',
                }
              }}
            />

            {/* Password Input */}
            <TextField
              fullWidth
              variant="outlined"
              label="Password"
              placeholder= "Enter your password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              error={!passwordValid && formSubmitted}
              helperText={!passwordValid && formSubmitted ? 'Password is required' : ''}
              InputProps={{
                startAdornment: <Lock sx={{ mr: 1 }} />,
                endAdornment: (
                  <IconButton onClick={toggleShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              sx={{
                mb: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                },
                '& .MuiInputBase-input': {
                  backgroundColor: 'transparent',
                }
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
            >
              Login
            </Button>

            {/* Forgot Password Link */}
            <Typography
              variant="body2"
              color="primary"
              align="center"
              sx={{ cursor: 'pointer' }}
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </Typography>


            <Typography variant="body2" color="textSecondary" align="center">
              &copy; {new Date().getFullYear()} Zventure Corporation. All rights reserved.
            </Typography>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
