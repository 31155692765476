import React, { useState } from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import './styles.css';

// Sample monthly data
const monthlyData = [
  { name: 'January', sales: 4000, revenue: 2400 },
  { name: 'February', sales: 3000, revenue: 1398 },
  { name: 'March', sales: 2000, revenue: 9800 },
  { name: 'April', sales: 2780, revenue: 3908 },
  { name: 'May', sales: 1890, revenue: 4800 },
  { name: 'June', sales: 2390, revenue: 3800 },
  { name: 'July', sales: 3490, revenue: 4300 },
];

// Generate last 7 days data
const generateDailyData = () => {
  const data = [];
  const today = new Date();
  
  for (let i = 6; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    
    const formattedDate = date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
    
    // Sample random sales and revenue for demonstration
    const sales = Math.floor(Math.random() * 500) + 100;
    const revenue = Math.floor(Math.random() * 300) + 50;

    data.push({ name: formattedDate, sales, revenue });
  }
  
  return data;
};

const dailyData = generateDailyData();

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-label">{`${payload[0].name}`}</p>
        <p>{`Sales: ${payload[0].value}`}</p>
        <p>{`Revenue: ${payload[1].value}`}</p>
      </div>
    );
  }
  return null;
};

function BarChart() {
  const [view, setView] = useState('monthly'); // State to manage the view
  const data = view === 'monthly' ? monthlyData : dailyData;

  return (
    <div style={{ width: '100%', height: '100%', padding: '20px' }}>
      <div className="view-buttons">
        <button className={`view-button ${view === 'monthly' ? 'active' : ''}`} onClick={() => setView('monthly')}>Monthly View</button>
        <button className={`view-button ${view === 'daily' ? 'active' : ''}`} onClick={() => setView('daily')}>Daily View</button>
      </div>
      <div style={{ height: 400, marginTop: '20px' }}>
        <ResponsiveContainer>
          <RechartsBarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            barCategoryGap="15%"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="sales" fill="#8884d8" barSize={30}>
              <LabelList dataKey="sales" position="top" />
            </Bar>
            <Bar dataKey="revenue" fill="#82ca9d" barSize={30}>
              <LabelList dataKey="revenue" position="top" />
            </Bar>
          </RechartsBarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default BarChart;
