import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, IconButton, Button, Avatar } from '@mui/material';
import { Visibility, VisibilityOff, Lock } from '@mui/icons-material';
import CryptoJS from 'crypto-js';  // Import crypto-js for encryption
import Logo from '../../logo-r.png';
import LoginBG from '../../assets/bg/login-bg.jpg';

const SetNewPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const navigate = useNavigate();

  const encryptionKey = 'your-encryption-key'; // Replace with your key for encryption

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordValid(e.target.value.length > 0 || !formSubmitted);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordValid(e.target.value.length > 0 || !formSubmitted);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const isPasswordValid = password.length > 0;
    const isConfirmPasswordValid = confirmPassword.length > 0;

    setPasswordValid(isPasswordValid || !formSubmitted);
    setConfirmPasswordValid(isConfirmPasswordValid || !formSubmitted);

    if (isPasswordValid && isConfirmPasswordValid) {
      if (password === confirmPassword) {
        // Encrypt the password before submission
        const encryptedPassword = CryptoJS.AES.encrypt(password, encryptionKey).toString();

        // You can now send `encryptedPassword` to the server
        console.log("Encrypted Password:", encryptedPassword);
        
        // Example: navigate to login page after password set
        navigate('/login');
      } else {
        setErrorMessage("Passwords do not match");
      }
    } else {
      setErrorMessage('');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        backgroundImage: `url(${LoginBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '800px',
          height: '600px',
          display: 'flex',
          flexDirection: 'row',
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: '8px',
          boxShadow: 2,
        }}
      >
        {/* Left Area */}
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#9acb4eb0' }}>
          <Avatar src={Logo} sx={{ width: '100%', height: 'auto', maxWidth: '500px', padding: '20px' }} />
        </Box>

        {/* Right Area */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <Typography variant="h5" gutterBottom>Set New Password</Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>Zventure Corporation</Typography>

          {/* Error Messages */}
          {(errorMessage || (!passwordValid && formSubmitted) || (!confirmPasswordValid && formSubmitted)) && (
            <Box sx={{ mb: 2, color: 'red' }}>
              <Typography variant="body2">
                {errorMessage || (!passwordValid && formSubmitted ? 'Password is required' : '') || (!confirmPasswordValid && formSubmitted ? 'Confirm Password is required' : '')}
              </Typography>
            </Box>
          )}

          <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
            {/* New Password Input */}
            <TextField
              fullWidth
              variant="outlined"
              label="New Password"
              placeholder="Enter your new password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              error={!passwordValid && formSubmitted}
              helperText={!passwordValid && formSubmitted ? 'Password is required' : ''}
              InputProps={{
                startAdornment: <Lock sx={{ mr: 1 }} />,
                endAdornment: (
                  <IconButton onClick={toggleShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              sx={{
                mb: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                },
                '& .MuiInputBase-input': {
                  backgroundColor: 'transparent',
                },
              }}
            />

            {/* Confirm Password Input */}
            <TextField
              fullWidth
              variant="outlined"
              label="Confirm Password"
              placeholder="Confirm your password"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={!confirmPasswordValid && formSubmitted}
              helperText={!confirmPasswordValid && formSubmitted ? 'Confirm Password is required' : ''}
              InputProps={{
                startAdornment: <Lock sx={{ mr: 1 }} />,
                endAdornment: (
                  <IconButton onClick={toggleShowConfirmPassword} edge="end">
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              sx={{
                mb: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                },
                '& .MuiInputBase-input': {
                  backgroundColor: 'transparent',
                },
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
            >
              Set Password
            </Button>
          </form>

          <Typography variant="body2" color="textSecondary" align="center">
            &copy; {new Date().getFullYear()} Zventure Corporation. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SetNewPassword;
