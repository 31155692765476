import React, { useState } from 'react';
import { TextField, InputLabel, MenuItem, Select, FormControl, IconButton, Button } from '@mui/material';
import { 
  Image as ImageIcon, 
  AddCircle as AddCircleIcon, 
  Remove as RemoveIcon 
} from '@mui/icons-material';

function AddProduct() {
  const [customProperties, setCustomProperties] = useState([{ name: '', value: '' }]);
  const [keywords, setKeywords] = useState([]);
  const [keywordInput, setKeywordInput] = useState('');
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState('');
  const [imagePreview, setImagePreview] = useState('');

  // New state variables for additional fields
  const [manufactureDate, setManufactureDate] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [bonusOfferQty, setBonusOfferQty] = useState('');
  const [status, setStatus] = useState('');

  const handlePropertyChange = (index, field, value) => {
    const newProperties = [...customProperties];
    newProperties[index][field] = value;
    setCustomProperties(newProperties);
  };

  const handleAddProperty = () => {
    setCustomProperties([...customProperties, { name: '', value: '' }]);
  };

  const handleRemoveProperty = (index) => {
    const newProperties = customProperties.filter((_, i) => i !== index);
    setCustomProperties(newProperties);
  };

  const handleKeywordChange = (e) => {
    setKeywordInput(e.target.value);
  };

  const handleAddKeyword = () => {
    if (keywordInput.trim()) {
      setKeywords((prevKeywords) => [
        ...prevKeywords,
        ...keywordInput.split(',').map(keyword => keyword.trim()).filter(Boolean)
      ]);
      setKeywordInput('');
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    setKeywords(keywords.filter(keyword => keyword !== keywordToRemove));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const validImageTypes = ['image/jpeg', 'image/png'];
    const maxSizeInBytes = 350 * 1024; // 350KB

    if (file) {
      if (!validImageTypes.includes(file.type)) {
        setImageError('Please upload a JPG or PNG image.');
        setImage(null);
        setImagePreview('');
        return;
      }

      if (file.size > maxSizeInBytes) {
        setImageError('Image size should not exceed 350KB.');
        setImage(null);
        setImagePreview('');
        return;
      }

      setImage(file);
      setImageError('');
      
      // Create a preview URL for the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    console.log({
      manufactureDate,
      expireDate,
      bonusOfferQty,
      status,
      // other form data...
    });
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Add Product</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form onSubmit={handleSubmit}>
            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">
                Basic Info
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  className="mt-1"
                />
                <FormControl fullWidth variant="outlined" className="mt-1">
                  <InputLabel>Category</InputLabel>
                  <Select
                    label="Category"
                    defaultValue=""
                  >
                    <MenuItem value="category1">Category 1</MenuItem>
                    <MenuItem value="category2">Category 2</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined" className="mt-1">
                  <InputLabel>Group Type</InputLabel>
                  <Select
                    label="Group Type"
                    defaultValue=""
                  >
                    <MenuItem value="type1">Type 1</MenuItem>
                    <MenuItem value="type2">Type 2</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Pack Size"
                  variant="outlined"
                  fullWidth
                  className="mt-1"
                />
                <TextField
                  label="Initial Stock"
                  variant="outlined"
                  type="number"
                  fullWidth
                  className="mt-1"
                />
                <TextField
                  label="Low Stock Threshold"
                  variant="outlined"
                  type="number"
                  fullWidth
                  className="mt-1"
                />
              </div>

              {/* Row with Status, Manufacture Date, Expire Date, and Bonus Offer Qty */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
                <FormControl fullWidth variant="outlined" className="mt-1">
                  <InputLabel>Status</InputLabel>
                  <Select
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="private">Private</MenuItem>
                    <MenuItem value="public">Public</MenuItem>
                    <MenuItem value="gift">Gift</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Manufacture Date"
                  type="date"
                  variant="outlined"
                  fullWidth
                  className="mt-1"
                  InputLabelProps={{ shrink: true }}
                  value={manufactureDate}
                  onChange={(e) => setManufactureDate(e.target.value)}
                />
                <TextField
                  label="Expire Date"
                  type="date"
                  variant="outlined"
                  fullWidth
                  className="mt-1"
                  InputLabelProps={{ shrink: true }}
                  value={expireDate}
                  onChange={(e) => setExpireDate(e.target.value)}
                />
                <TextField
                  label="Bonus Offer Qty"
                  type="number"
                  variant="outlined"
                  fullWidth
                  className="mt-1"
                  value={bonusOfferQty}
                  onChange={(e) => setBonusOfferQty(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-6 pb-4 border-b-2 border-gray-300 flex flex-col md:flex-row gap-4">
              {/* Image and Keyword Section remains unchanged */}
              <div className="flex-1">
                <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">
                  <ImageIcon className="text-customGreen mr-2" /> Image
                </h4>
                <div className="border border-gray-300 rounded-md p-4 text-center">
                  <p className="text-gray-600">Upload Product Image</p>
                  <input
                    type="file"
                    accept="image/jpeg, image/png"
                    onChange={handleImageChange}
                    className="mt-2"
                  />
                  {image && (
                    <div className="mt-2">
                      <p className="text-gray-500">Selected file: {image.name}</p>
                      {imagePreview && <img src={imagePreview} alt="Preview" className="mt-2 max-w-full h-auto rounded-md" />}
                    </div>
                  )}
                  {imageError && <p className="mt-2 text-red-500">{imageError}</p>}
                </div>
              </div>

              <div className="flex-1">
                <div className="mb-6">
                  <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">
                    Keywords
                  </h4>
                  <TextField
                    label="Add Keywords"
                    variant="outlined"
                    fullWidth
                    value={keywordInput}
                    onChange={handleKeywordChange}
                    className="mb-2"
                  />
                  <Button
                    onClick={handleAddKeyword}
                    className="bg-customGreen text-white hover:bg-green-600"
                    startIcon={<AddCircleIcon />}
                  >
                    Add Keyword
                  </Button>
                  <div className="mt-2">
                    {keywords.map((keyword, index) => (
                      <div key={index} className="inline-flex items-center bg-gray-200 rounded-full px-3 py-1 text-sm font-medium text-gray-800 mr-2 mb-2">
                        {keyword}
                        <IconButton
                          onClick={() => handleRemoveKeyword(keyword)}
                          className="ml-2"
                        >
                          <RemoveIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">
                Product Description
              </h4>
              <TextField
                label="Product Description"
                variant="outlined"
                fullWidth
                multiline
                rows={8}
              />
            </div>

            <div className="flex gap-4 mt-6">
              <Button
                type="submit"
                className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Save
              </Button>
              <Button
                type="reset"
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Clear
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
